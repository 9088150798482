import React, { useEffect, useState } from "react";
import { getAllQuarterDates } from "../API_quarters";
import { IQuarterly } from "../types";
import AddQuarterModal from "./add_quarter_modal";
import EditQuarterModal from "./edit_quarter_modal";

function EditQuarter() {
    const [quarters, setQuarters] = useState<IQuarterly[]>([]);
    const [selectedQuarter, setSelectedQuarter] = useState<IQuarterly>(Object);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showAllQuarters, setShowAllQuarters] = useState<boolean>(false);

    useEffect(() => {
        if (showEditModal || showAddModal) {
            return;
        }

        getAllQuarterDates().then((res) => {
            setQuarters(res.data);
        });
    }, [showEditModal, showAddModal]);

    function handleAddQuarter() {
        setShowAddModal(true);
    }

    function handleEditQuarter(quarter: IQuarterly) {
        setSelectedQuarter(quarter);
        setShowEditModal(true);
    }

    function findCurrentQuarterIndex(quarters: IQuarterly[]): number {
        if (quarters.length === 0) {
            return -1;
        }
        const today = new Date().getTime();
        const quarterDates = quarters.map((q) =>
            new Date(q.quarterDate).getTime(),
        );

        const nextQuarterStart = quarters.findIndex(
            (q) => new Date(q.quarterDate).getTime() > today,
        );
        if (nextQuarterStart < 0) {
            return -1;
        }
        return nextQuarterStart - 1;
    }

    function filterQuarters(
        quarters: IQuarterly[],
        showAllQuarters: boolean,
    ): IQuarterly[] {
        if (showAllQuarters) {
            return quarters;
        }
        const currentQuarterIndex = findCurrentQuarterIndex(quarters);
        if (currentQuarterIndex < 0) {
            const latestQuarter = quarters.at(currentQuarterIndex);
            if (latestQuarter) {
                return [latestQuarter];
            } else {
                return [];
            }
        }
        const filteredQuarters: IQuarterly[] = [];

        for (let i = currentQuarterIndex; i < quarters.length; i++) {
            filteredQuarters.push(quarters[i]);
        }

        return filteredQuarters;
    }

    function listQuarters(quarters: IQuarterly[]) {
        if (quarters.length === 0) {
            return <></>;
        }
        const currentQuarter = quarters.at(findCurrentQuarterIndex(quarters));
        if (!currentQuarter) {
            return <></>;
        }
        return quarters.map((quarter, index) => {
            const quarterType = quarter.quarter;
            const quarterStart = new Date(quarter.quarterDate).toDateString();
            const quarterYear = quarter.year;

            const currentQuarterSymbol =
                quarter.quarter === currentQuarter.quarter &&
                quarter.year === currentQuarter.year
                    ? "✔"
                    : "";

            return (
                <tr className="table-row" key={index}>
                    <td className="table-data">{currentQuarterSymbol}</td>
                    <td className="table-data">{quarterType}</td>
                    <td className="table-data">{quarterYear}</td>
                    <td className="table-data">{quarterStart}</td>
                    <td className="table-data">
                        <button
                            className="manage-button"
                            onClick={() => handleEditQuarter(quarter)}
                        >
                            {`Edit`}
                        </button>
                    </td>
                </tr>
            );
        });
    }

    return (
        <div className="timesheet-container">
            <p className="timesheet-title">Quarter Start Dates</p>
            <button
                className="manage-button"
                onClick={() => handleAddQuarter()}
            >
                Add Quarter Start Date
            </button>
            <select
                value={showAllQuarters ? "showAllQuarters" : ""}
                onChange={(e) => setShowAllQuarters(Boolean(e.target.value))}
            >
                <option value="">Show Upcoming Quarters</option>
                <option value="showAllQuarters">Show All Quarters</option>
            </select>
            <div className="timesheet">
                <table className="time-table">
                    <thead>
                        <tr>
                            <th className="table-headers">Current</th>
                            <th className="table-headers">Quarter</th>
                            <th className="table-headers">Year</th>
                            <th className="table-headers">Start Date</th>
                            <th className="table-headers">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listQuarters(
                            filterQuarters(quarters, showAllQuarters),
                        )}
                    </tbody>
                </table>
            </div>
            {showEditModal && (
                <EditQuarterModal
                    setModal={setShowEditModal}
                    quarter={selectedQuarter}
                />
            )}
            {showAddModal && (
                <AddQuarterModal
                    setModal={setShowAddModal}
                    currentQuarters={quarters}
                />
            )}
        </div>
    );
}

export default EditQuarter;
