import React, { useState } from "react";
import { addEmployee } from "../API_employee";
import { IEmployee } from "../types";

interface AddModalProps {
    setModal: Function;
}

interface Employee {
    firstName: string;
    lastName: string;
    department: string;
    weeklyHours: number;
    quarterHours: number;
    totalHours: number;
    isClockedIn: boolean;
    isAutoClocked: boolean;
}

const AddEmployeeBulkModal = (props: AddModalProps) => {
    const { setModal } = props;
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [error, setError] = useState<string>("");

    function createEmployee(
        firstName: string,
        lastName: string,
        department: string,
    ): Employee {
        const employee: Employee = {
            firstName: firstName,
            lastName: lastName,
            department: department,
            weeklyHours: 0,
            quarterHours: 0,
            totalHours: 0,
            isClockedIn: false,
            isAutoClocked: false,
        };

        return employee;
    }

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const file = e.target.files?.[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = function () {
            const content = reader.result as string;
            const lines = content.split("\n").map((line) => line.trim());
            const parsedEmployees: Employee[] = [];

            for (const line of lines) {
                if (!line) {
                    continue;
                }

                const [firstName, lastName, department] = line.split(",");
                if (!firstName || !lastName || !department) {
                    setError(
                        `Failed to parse CSV. Line "${line}" is malformed.\nPlease format lines as "firstName,lastName,department".`,
                    );
                    e.target.value = "";
                    return;
                }

                parsedEmployees.push(
                    createEmployee(firstName, lastName, department),
                );
            }

            setError("");
            setEmployees(parsedEmployees);
             //feedback message
            window.alert(`${parsedEmployees.length} employees have been successfully parsed from the CSV.`);
        };

        reader.readAsText(file);
    }

    async function addEmployees() {
        for (const employee of employees) {
            try {
                await addEmployee(employee as IEmployee);
            } catch (e) {
                setError(e as string);
                return false;
            }
        }
        return true;
    }

    async function handleConfirm() {
        const success = await addEmployees();
        if (!success) {
            return;
        }
        setError("");
        setModal(false);
        window.alert("Employees have been successfully added!");
    }

    return (
        <div className="modal-background">
            <div className="clock-modal">
                <div className="close-modal">
                    <button onClick={() => props.setModal(false)}>x</button>
                </div>
                <div className="modal-title">
                    <h1>Add employee from CSV</h1>
                </div>
                <div className="modal-body">
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                    />
                </div>
                {error && (
                    <div style={{ color: "darkred", margin: "1rem" }}>
                        {error}
                    </div>
                )}
                <div className="modal-footer">
                    <button
                        className="modal-cancel"
                        onClick={() => {
                            props.setModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button className="modal-confirm" onClick={handleConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddEmployeeBulkModal;
